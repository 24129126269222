import { Helmet } from "react-helmet";

const PageTitle = ({ title }) => {
	return (
		<Helmet>
			<title>{title} - The Colony Logistics Ltd</title>
		</Helmet>
	);
};

export default PageTitle;
