export const ImgBox = ({ imgUrl, alt, ...rest }) => {
	return (
		<div className="img-box img" data-aos="fade-in">
			<img src={imgUrl} {...rest} alt={alt} />
		</div>
	);
};

export const ImgContainerLeft = ({ imgUrl, alt, ...rest }) => {
	return (
		<div className="img-container-left">
			<img src={imgUrl} alt={alt} {...rest} />
		</div>
	);
};

export const ImgContainerRight = ({ imgUrl, alt, ...rest }) => {
	return (
		<div className="img img-container-right" data-aos="fade-in">
			<img src={imgUrl} alt={alt} {...rest} />
		</div>
	);
};
